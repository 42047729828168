<template>
  <div class="buy-page">
    <oAddress :addressArr.sync="addressArr"></oAddress>
    <oProduct ref="product" state="buy" :goods="form.fmt_show_list"></oProduct>

    <oVouchers :vouchers="form.vouchers" :voucher.sync="voucher"></oVouchers>
    <ul class="data-info">
      <li class="_info-list">
        <div class="title">商品总金额</div>
        <div class="price">
          <i>￥</i><span>{{ form.goods_total_price }}</span>
        </div>
      </li>
      <li class="_info-list">
        <div class="title">运费</div>
        <div class="price">
          <i>￥</i><span>{{ form.freight }}</span>
        </div>
      </li>
      <li class="_info-list" v-if="false">
        <div class="title">合计</div>
        <div class="price" style="color:#FF4848">
          <i>￥</i
          ><span>{{
            form.total_price +
              form.freight -
              ((voucher && voucher.voucher_price) || 0)
          }}</span>
        </div>
      </li>
    </ul>

    <oPay
      state="buy"
      @errorEmit="errorEmit"
      :voucher="voucher"
      :form="form"
      :addressArr="addressArr"
      :cartForm="cartForm"
      :balancePayState="balancePayState"
      :remarks="remarks"
    ></oPay>

    <oBalance
      v-if="form.member_info && form.member_info.available_predeposit"
      :balance="form.member_info.available_predeposit"
      :totalPrice="
        form.total_price +
          form.freight -
          ((voucher && voucher.voucher_price) || 0)
      "
      :balancePayState.sync="balancePayState"
    ></oBalance>
  </div>
</template>

<script>
import oAddress from "../buy/components/address";
import oProduct from "./components/product.vue";
import oBalance from "./components/balance";
import oVouchers from "./components/vouchers";
import oPay from "./components/pay";
import oRemarks from "./components/remarks";
import { post } from "@get/http";
export default {
  components: {
    oAddress,
    oRemarks,
    oProduct,
    oBalance,
    oVouchers,
    oPay,
  },
  data() {
    return {
      addressArr: {},
      remarks: "", //用户备注
      form: {}, //请求的数据
      cartForm: [], //购物车数据
      voucher: {}, //选中代金券
      len: 0, //产品数量
      balancePayState: false, //是否零钱支付
      errorText: "",
    };
  },
  methods: {
    errorEmit(data) {
      this.errorText = data;
    },
    async getOriderList() {
      let data = await post(
        "/v3.MemberPresaleGoods/previewPresaleGoodsBalanceOrder",
        {
          data: {
            deposit_ids: this.cartForm,
            address_id: this.addressArr.address_id,
          },
        }
      );
      this.form = data;
    },
  },
  async created() {
    let r = this.$route.query;
    let cart_id = r.cart_id;
    let deposit_ids = cart_id.split(",");
    let data = await post("/v3.MemberPresaleGoods/getUserAddress");
    this.addressArr = data.address_info;
    if (deposit_ids) {
      this.cartForm = deposit_ids;
    }
  },
  mounted() {
    // console.log(this.$refs.product.$refs);
  },
  watch: {
    addressArr() {
      console.log(this.addressArr);
      this.getOriderList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.buy-page {
  padding-bottom: rem(200);
}
.data-info {
  background: #fff;
  width: rem(710);
  margin: 0 auto rem(24);
  border-radius: rem(16);
  padding: rem(20);
  box-sizing: border-box;

  ._info-list {
    display: flex;
    margin-bottom: rem(20);
    &:last-of-type {
      margin-bottom: 0;
    }
    .title {
      font-weight: 400;
      font-size: rem(28);
      line-height: rem(39);
      color: #222222;
    }
    .price {
      flex: 1;
      font-weight: 600;
      font-size: rem(30);
      line-height: rem(42);
      text-align: right;
      > span {
        margin-left: rem(6);
      }
    }
  }
}
</style>
